angular.module("adminApp")
    .factory('editOrgInfoAdminModal', ["$uibModal","CountriesListService", function ($uibModal, CountriesListService) {
        function open(orgId, contactDetails, showOrgInfoDetails) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    orgId: function () {
                        return orgId;
                    },
                    contactDetails: function () {
                        return contactDetails;
                    },
                    showOrgInfoDetails: function () {
                        return showOrgInfoDetails;
                    }
                },
                templateUrl: "/static/templates/admin/modals/editOrgInfoModal.html",
                controller: "EditOrgInfoAdminModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("EditOrgInfoAdminModalCtrl", ["$scope", "$uibModalInstance", "aerosAdminApi", "orgId", "contactDetails", "showOrgInfoDetails", "Notification", "CountriesListService", function ($scope, $uibModalInstance, aerosAdminApi, orgId, contactDetails, showOrgInfoDetails, Notification, CountriesListService) {


        (function init() {
            $scope.countries = CountriesListService.getCountries();


            $scope.orgInfoModel = contactDetails;
            $scope.selectedFeatures = [];

            aerosAdminApi.featureApi.list().then(function (results) {
                $scope.availableFeatures = results.data.features;

                aerosAdminApi.featureApi.listByOrganization($scope.orgInfoModel.organizationId).then(function (results) {
                    angular.forEach(results.data.features, function (item) {
                        $scope.selectedFeatures[item.name] = true;
                    });
                });
            });

            aerosAdminApi.vendorApi.list().then(function (results) {
                $scope.vendors = results.data.vendors;
            });
        })();


        $scope.saveOrgInfo = function () {
            console.log("saveOrgInfo: ", $scope.orgInfoModel);
            aerosAdminApi.editContact(orgId, contactDetails.id, $scope.orgInfoModel)
                .success(function () {
                    aerosAdminApi.featureApi.updateOrganization($scope.orgInfoModel.organizationId,
                        prepareFeatureListToUpdateOrganization($scope.availableFeatures, $scope.selectedFeatures))
                        .then(function () {
                            $uibModalInstance.close();
                            Notification.success("Contact information saved");
                        });
                })
                .error(function (err) {
                    Notification.error("Contact information not saved. " + err);
                });


        };


        function prepareFeatureListToUpdateOrganization(availableFeatures, selectedFeatures) {
            return availableFeatures.filter(function (element) {
                return selectedFeatures[element.name];
            })
        };

        $scope.autoSelectFeaturesForVendor = function(vendor) {
            $scope.availableFeatures.map(function(feature){
            $scope.selectedFeatures[feature.name] = vendor.toLowerCase() === 'afl';
            });
        }

    }]);
